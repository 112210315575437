<template>
    <div class="spotmax-cost-view">
        <div class="ctrl-bar">
            <div class="label">报表月份：</div>
            <n-select
                v-model:value="yearMonth"
                :options="yearMonthOpts"
                class="input"
                size="small"
                style="width: 100px"
                @update:value="onQueryChange"
            ></n-select>

            <n-button class="submit" size="tiny" text @click="onCSVExportClick">
                <template #icon>
                    <n-icon>
                        <table-view-filled />
                    </n-icon>
                </template>
                导出CSV
            </n-button>
        </div>
        <div class="content">
            <n-data-table
                size="small"
                :loading="loading"
                :data="tableData"
                :columns="tableColumns"
                :scroll-x="1700"
                :single-line="false"
                :max-height="tableHeight"
            ></n-data-table>
        </div>
    </div>
</template>

<style lang="less" scoped>
@import '../../../common/common.less';
.spotmax-cost-view {
    .common-content;

    .ctrl-bar {
        .common-ctrl-bar;
    }

    .content {
        margin-top: 10px;
        background-color: #fff;
    }
}
</style>

<script setup>
import { ref, computed, onMounted } from 'vue';
import { useRoute, useRouter } from 'vue-router';
import {
    NButton,
    NIcon,
    NSelect,
    NDataTable,
    NTooltip,
    useMessage,
} from 'naive-ui';
import { TableViewFilled } from '@vicons/material';

import dayjs from 'dayjs';
import { isNullOrUndefined } from '@/common/tools';
import exportCSV from '@/common/CSVGenerator';
import { BusinessAnalysisAPI } from '@/common/API';

const route = useRoute();
const router = useRouter();
const message = useMessage();

// 表格组件高度（适配屏幕高度）
let tableHeight = ref(0);

function genDateOpts() {
    let opts = [];
    let dayObj = dayjs().startOf('month');
    // 报表累积起始月：2023-11
    while (!dayObj.isBefore('2023-11-01', 'month')) {
        let text = `${dayObj.year()}-${dayObj.month() + 1}`;
        opts.push({ label: text, value: text });
        dayObj = dayObj.subtract(1, 'month');
    }
    return opts;
}

let yearMonthOpts = ref(genDateOpts());

let yearMonth = ref(yearMonthOpts.value[0].value);

let loading = ref(false);

let monthlyDateQuery = computed(() => {
    let [year, month] = yearMonth.value.split('-');
    return { year, month };
});

let dailyDateQuery = computed(() => {
    let dayObj = dayjs(
        `${monthlyDateQuery.value.year}-${monthlyDateQuery.value.month}-1`
    );
    return {
        start: dayObj.format('YYYY-MM-DD'),
        end: dayObj.endOf('month').format('YYYY-MM-DD'),
    };
});

function onQueryChange() {
    router.replace({
        query: { year_month: yearMonth.value },
    });
    loadData();
}

let tableData = ref([]);

let columnsData = ref({});

let tableColumns = computed(() => {
    let dateCol = {
        key: 'date',
        title: '日期',
        width: 100,
    };
    let customCols = Object.keys(columnsData.value).map(key => ({
        key,
        title(col) {
            return (
                <NTooltip
                    trigger="hover"
                    v-slots={{
                        trigger: () => {
                            return columnsData.value[key].name;
                        },
                    }}
                >
                    {'国家:' +
                        columnsData.value[key].country +
                        ' 行业:' +
                        columnsData.value[key].industry}
                </NTooltip>
            );
        },
        minWidth: 100,
        resizable: true,
        align: 'center',
        render(row) {
            let val = !isNullOrUndefined(row[key]) && row[key].toFixed(2);
            if (row.date !== '汇总') {
                return val;
            }
            return (
                <NTooltip
                    trigger="hover"
                    disabled={!resaleData.value[key]}
                    v-slots={{
                        trigger: () => {
                            return val;
                        },
                    }}
                >
                    {`Resale Credit:${resaleData.value[key].toFixed(2)}+${row[
                        key
                    ].toFixed(2)}=${(resaleData.value[key] + row[key]).toFixed(
                        2
                    )}`}
                </NTooltip>
            );
        },
    }));

    return [dateCol, ...customCols];
});

let resaleData = ref({});

function loadData() {
    loading.value = true;
    BusinessAnalysisAPI.getSpotMaxDailyCost(
        dailyDateQuery.value.start,
        dailyDateQuery.value.end
    ).then(res => {
        loading.value = false;
        if (res.error_no !== 0) {
            message.error(res.error_msg || '未知异常导致加载失败');
            return;
        }
        tableData.value = res.data ? res.data.list : [];
        columnsData.value = res.data ? res.data.customer_data : {};
        resaleData.value = res.data ? res.data.resale_credit : {};
    });
}

function onCSVExportClick() {
    let csvColumns = tableColumns.value.map(col => {
        return {
            key: col.key,
            label:
                col.key === 'date' ? '日期' : columnsData.value[col.key].name,
        };
    });
    exportCSV(tableData.value, csvColumns, { file: 'spot_max_cost.csv' });
}

onMounted(() => {
    tableHeight.value = document.body.clientHeight - 220;
    if (route.query.year_month) {
        yearMonth.value = route.query.year_month;
    }
    loadData();
});

window.addEventListener('resize', () => {
    tableHeight.value = document.body.clientHeight - 220;
});
</script>
